import { API } from '../api';
import { axiosInstance } from '../axios';
import { IBasketData } from './types';

export const getBasket = async () => {
  try {
    const result = await axiosInstance.get<IBasketData>(API.getBasket, { cache: false });
    console.log(result.data?.data, 'result.data?.data');

    return result.data?.data;
  } catch (e: any) {
    console.error(e);
  }
};
