import { create } from 'zustand';
import { useCityStore } from './cityStore';

interface FiltersStoreState {
  filters: {
    [code: string]: string[];
  };
  rangeFilters: {
    [name: string]: { min: number; max: number };
  };
  initialSectionId?: number | null;
  initialLabelId?: number | null;
  page: number;
  query?: string;
  sort_by?: 'price' | 'popular';
  sort_order?: 'asc' | 'desc';
  shop_ids?: number[];
}

interface FiltersStoreActions {
  setFilter: (code: string, value: string, add: boolean) => void;
  setRangeFilter: (code: string, values: { min: number; max: number }) => void;
  setPage: (page: number) => void;
  setQuery: (query: string) => void;
  setSort: (sort_by: 'price' | 'popular', sort_order: 'asc' | 'desc') => void;
  resetFilters: () => void;
  resetFilterByCode: (code: string) => void;
  resetSelectedShopsId: () => void;
  setInitialSectionId: (id: number | null) => void;
  setInitialLabelId: (id: number | null) => void;
  getTotalSelectedFilters: () => number;
  getSelectedFiltersCountByCode: (code: string) => number;
  getFilterBody: () => {
    filter: any;
    page: number;
    query?: string;
    sort_by?: string;
    sort_order?: string;
  };
  setShopId: (shop_id: number, add: boolean) => void;
  resetShopIds: () => void;
  getShopIds: () => number[];
}

export const useFiltersStore = create<FiltersStoreState & FiltersStoreActions>((set, get) => ({
  sort_by: 'popular',
  sort_order: 'asc',
  filters: {},
  rangeFilters: {},
  page: 1,
  query: '',
  initialSectionId: null,
  initialLabelId: null,
  setInitialSectionId(id) {
    set({ initialSectionId: id });
  },
  setInitialLabelId(id) {
    set({ initialLabelId: id });
  },
  setFilter: (code, value, add) => {
    set((state) => {
      const currentValues = state.filters[code] || [];
      let newValues;

      if (add) {
        newValues = [...currentValues, value];
      } else {
        newValues = currentValues.filter((item) => item !== value);
      }

      if (code === 'SECTION') {
        newValues = [value];
      }

      const newFilters = { ...state.filters };
      if (newValues.length === 0) {
        delete newFilters[code];
      } else {
        newFilters[code] = newValues;
      }

      return {
        filters: newFilters,
      };
    });
  },
  setRangeFilter: (name, values) => {
    set((state) => ({
      rangeFilters: {
        ...state.rangeFilters,
        [name]: values,
      },
    }));
  },
  setPage: (page) => {
    set({ page });
  },
  setQuery: (query) => {
    set({ query });
  },
  setSort: (sort_by, sort_order) => {
    set({ sort_by, sort_order });
  },
  resetFilters: () => {
    set(() => ({
      filters: {
        SECTION: get().filters.SECTION,
      },
      rangeFilters: {},
      shop_ids: undefined,
      sort_by: 'popular',
      sort_order: 'asc',
    }));
  },
  resetSelectedShopsId: () => {
    set(() => ({
      shop_ids: undefined,
    }));
  },
  resetFilterByCode: (code) => {
    set((state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [code]: _, ...remainingFilters } = state.filters;
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [code]: __,
        ...remainingRangeFilters
      } = state.rangeFilters;

      return {
        filters: remainingFilters,
        rangeFilters: remainingRangeFilters,
      };
    });
  },
  getTotalSelectedFilters: () => {
    const state = get();
    const count = Object.entries(state.filters).filter((current) => {
      if (current[0] === 'NAZNACHENIE') {
        return false;
      }
      if (current[0] === 'SECTION') {
        // return (
        //   Array.isArray(current[1]) &&
        //   current[1].length > 1
        // );
        return false;
      }
      if (current[0] === 'LABEL') {
        return Array.isArray(current[1]) && current[1].length > 1;
      }
      return Array.isArray(current[1]) && current[1].length > 0;
    }).length;

    const selectedShops = state.shop_ids || [];
    return count + (selectedShops.length > 0 ? 1 : 0);
  },
  setShopId: (value, add) => {
    set((state) => {
      const currentValues = state.shop_ids || [];
      let newValues;

      if (add && !currentValues.includes(value)) {
        newValues = [...currentValues, value];
      } else {
        newValues = currentValues.filter((item) => item !== value);
      }

      return {
        shop_ids: newValues,
      };
    });
  },
  resetShopIds() {
    set({ shop_ids: [] });
  },
  getSelectedFiltersCountByCode: (code: string) => {
    const state = get();
    let result = state.filters[code]?.length || 0;
    if (code === 'SHOPS') {
      return state.shop_ids?.length || 0;
    }
    if (code === 'SECTION') {
      result = result === 0 ? 0 : result - 1;
    }
    if (code === 'LABEL' && state.initialLabelId) {
      result = result === 0 ? 0 : result - 1;
    }
    return result;
  },
  getFilterBody: () => {
    const convertValuesToNumbers = (filters) => {
      const convertedFilters = {};
      Object.entries(filters).forEach(([key, values]) => {
        convertedFilters[key] = Array.isArray(values) ? values.map((value) => Number(value)) : values;
      });
      return convertedFilters;
    };

    const filters = { ...get().filters };
    const rangeFilters = get().rangeFilters;

    if (get().initialLabelId && filters.LABEL?.length && filters.LABEL?.length > 1) {
      filters.LABEL = filters.LABEL.filter((i) => i !== `${get().initialLabelId}`);
    }

    let expressShops = null;
    if (filters.expressDelivery) {
      delete filters.expressDelivery;
      expressShops = useCityStore.getState().shops.map((shop) => shop.store_id);
    }

    const result = {
      filter: {
        ...convertValuesToNumbers({
          ...filters,
          SECTION: filters.SECTION || [],
        }), // Фикс фильтров для страниц каталога без категории
        ...convertValuesToNumbers(rangeFilters),
      },
      page: get().page,
      query: get().query,
      sort_by: get().sort_by,
      sort_order: get().sort_order,
      shop_ids: expressShops || get().shop_ids,
      city: useCityStore.getState().city,
    };

    return result;
  },
  getShopIds() {
    return get().shop_ids || [];
  },
}));
