import { API } from '../api';
import { axiosInstance } from '../axios';
import { IUserData } from './types';

export const getUser = async () => {
  try {
    const result = await axiosInstance.get<IUserData>(API.getUser, { cache: false });
    return result.data?.data;
  } catch (e: any) {
    console.error(e);
  }
};
