// 10 минут, next принимает в секундах
export const REVALIDATE = Number(process.env.NEXT_PUBLIC_REVALIDATE) || 10 * 60 * 1;

// export const LONG_REVALIDATE = 10 * 60 * 1000;

export const CACHE_TTL_DEFAULT = 10 * 60 * 1000; // 10 минут, axios принимает в миллисекундах
export const CACHE_TTL = 10 * 60 * 1000; // 10 минут, axios принимает в миллисекундах
export const CACHE_TTL_FAVORITE_PRODUCTS = 10 * 1000; // 10 секунд, изначально было 3, нужно понять зачем
export const CACHE_TTL_SINGLE_PRODUCT = 10 * 60 * 1000; // 10 минут, изначально было 0 сек, нужно понять зачем

const getValuePrefetch = (str: string | undefined) => {
  switch (str) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return undefined;
  }
};

export const PREFETCH = {
  PRODUCT: getValuePrefetch(process.env.NEXT_PUBLIC_PREFETCH_LINK_PRODUCT),
  PRODUCTS: getValuePrefetch(process.env.NEXT_PUBLIC_PREFETCH_LINK_PRODUCTS),
  FILTERS: getValuePrefetch(process.env.NEXT_PUBLIC_PREFETCH_LINK_FILTERS),
  PAGINATION: getValuePrefetch(process.env.NEXT_PUBLIC_PREFETCH_LINK_PAGINATION),
  BREADCRUMBS: getValuePrefetch(process.env.NEXT_PUBLIC_PREFETCH_LINK_BREADCRUMBS),
  OTHER: getValuePrefetch(process.env.NEXT_PUBLIC_PREFETCH_LINK_OTHER),
};
