import { API } from '../api';
import { axiosInstance } from '../axios';
import { IBasketData } from '../getBasket/types';
import { BasketSetQuantityParams } from './types';

export const postBasketSetQuantity = async (body: BasketSetQuantityParams) => {
  try {
    const result = await axiosInstance.post<IBasketData>(API.postBasketSetQuantity(body.id), body, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return result.data?.data;
  } catch (e: any) {
    console.error(e);
  }
};
