import { create } from 'zustand';
import { getUser } from '../api/getUser';
import { IUser } from '../api/getUser/types';

interface UserStoreState {
  user: IUser | null;
  isLoading: boolean;
}

interface UserStoreActions {
  getUser: () => void;
  setIsLoading: (isLoading: boolean) => void;
}

export const useUserStore = create<UserStoreState & UserStoreActions>((set) => ({
  getUser: async () => {
    try {
      set({ isLoading: true });
      const response = await getUser();

      set({
        user: response,
      });
    } catch (error) {
      console.error('Failed to get user', error);
    } finally {
      set({ isLoading: false });
    }
  },
  setIsLoading: (isLoading: boolean) => {
    set({ isLoading });
  },
  isLoading: false,
  user: null,
}));
