// import { CACHE_TTL_FAVORITE_PRODUCTS } from '@/shared/constants';
import { API } from '../api';
import { axiosInstance } from '../axios';
import { IFavoritesData } from './types';

export const getFavoriteProducts = async () => {
  try {
    const result = await axiosInstance.get<IFavoritesData>(API.getFavorites, {
      // TODO: не уверен, что можно кэшировать
      cache: false,
      // cache: {
      //   ttl: CACHE_TTL_FAVORITE_PRODUCTS,
      // },
    });
    return result.data;
  } catch (e: any) {
    console.error(e);
  }
};
