import { create } from 'zustand';
import { getFavoriteProducts } from '../api/getFavorites';

interface FavoritesStoreState {
  favoriteProducts: number[] | null;
}

interface FavoritesStoreActions {
  setFavoriteProducts: (state: number[]) => void;
  addFavoriteProduct: (id: number) => void;
  deleteFavoriteProduct: (id: number) => void;
  getFavoriteProducts: () => void;
}

export const useFavoritesStore = create<FavoritesStoreState & FavoritesStoreActions>((set, get) => ({
  favoriteProducts: null,
  setFavoriteProducts: (productsIds: number[]) => {
    set(() => {
      return {
        favoriteProducts: productsIds,
      };
    });
  },
  addFavoriteProduct: (id: number) => {
    set(() => {
      const favProds = get().favoriteProducts || [];
      return {
        favoriteProducts: [...favProds, id],
      };
    });
  },

  deleteFavoriteProduct: (id: number) => {
    set(() => {
      const favProds = get().favoriteProducts || [];

      const newFavProds = favProds.filter((prodId) => prodId !== id);

      return {
        favoriteProducts: newFavProds,
      };
    });
  },
  getFavoriteProducts: async () => {
    const response = await getFavoriteProducts();
    if (response?.status === 'success') {
      const products = response.data;
      const ids = products.map((product) => product.product.id);
      get().setFavoriteProducts(ids);
    }
  },
}));
