import { create } from 'zustand';
import { getBasket } from '../api/getBasket';
import { IBasketItem } from '../api/getBasket/types';
import { getDeliveryCities } from '../api/getDeliveryCities';
import { postAvailByCity } from '../api/postAvailByCity';
import { IAmountItem } from '../api/postAvailByCity//types';
import { BasketAvailable, BasketAvailableProduct } from '../api/postAvailByCity/types';
import { postBasketAdd } from '../api/postBasketAdd';
import { postBasketDelete } from '../api/postBasketDelete';
import { postBasketDeleteArray } from '../api/postBasketDeleteArray';
import { postBasketPromocode } from '../api/postBasketPromocode';
import { postBasketSetQuantity } from '../api/postBasketSetQuantity';
import { postDeliveryBoxberryPoints } from '../api/postDeliveryBoxberryPoints';
import { IDeliveryPoint } from '../api/postDeliveryBoxberryPoints/types';
import { postDeliveryCdekPoints } from '../api/postDeliveryCdekPoints';
import { postDeliveryCdekTariff } from '../api/postDeliveryCdekTariff';
import { IDeliveryCdekTariff } from '../api/postDeliveryCdekTariff/types';
import { postDeliveryGetAll } from '../api/postDeliveryGetAll';
import { IDeliveryAll } from '../api/postDeliveryGetAll/types';
import { postDeliveryRussianPostPoints } from '../api/postDeliveryRussianPostPoints';
import { useCityStore } from './cityStore';
import { useFavoritesStore } from './favoritesStore';
import { useUserStore } from './userStore';

interface CartStoreState {
  isVisibleBackdrop: boolean;
  isLoading: boolean;
  isDeliveriesLoading: boolean;
  activeStore: IAmountItem | null;
  activePvz: any;
  cart: IBasketItem[];
  amount: IAmountItem[];
  amountCity: BasketAvailable | null;
  totalItems: number;

  cdekPoints: IDeliveryPoint[] | [];
  cdekTariff: IDeliveryCdekTariff | null;

  boxberryPoints: IDeliveryPoint[] | [];
  russianPostPoints: IDeliveryPoint[] | [];
  pvzCount: number;
  deliveries: IDeliveryAll | null;
  promocodeStatus: 'success' | 'error' | null;
  promocodeDiscount: number;
  promocode: string;
  promocodeError: string;
}

interface CartStoreActions {
  setActiveStore: (activeStore: any) => void;
  setActivePvz: (activeStore: any) => void;
  setCart: (cart: IBasketItem[]) => void;
  initializeCart: () => void;
  addToCart: (id: number) => void;
  setQuantity: (id: number, quantity: number) => void;
  setPromocode: (code: string) => void;
  resetPromocodeStatus: () => void;
  delete: (id: number) => void;
  deleteArray: (positions: number[]) => void;
  getDeliveries: () => Promise<void>;
  getAvailableProducts: () => BasketAvailableProduct[];
  getUnavailableProducts: () => BasketAvailableProduct[];
  getProductsByIds: (ids: number[]) => IBasketItem[];
  setPromocodeDiscount: (value: number) => void;
}

export const useCartStore = create<CartStoreState & CartStoreActions>((set, get) => ({
  cart: [],
  isVisibleBackdrop: false,
  isLoading: true,
  isDeliveriesLoading: false,
  totalItems: 0,
  activeStore: null,
  activePvz: null,
  amount: [],
  amountCity: null,
  cdekPoints: [],
  cdekTariff: null,
  boxberryPoints: [],
  russianPostPoints: [],
  deliveries: null,
  pvzCount: 0,
  promocodeStatus: null,
  promocodeDiscount: 0,
  promocodeError: '',
  setPromocodeDiscount(value) {
    set({ promocodeDiscount: value });
  },
  promocode: '',

  resetPromocodeStatus: () => {
    set({ promocodeStatus: null });
  },

  setPromocode: async (code: string) => {
    set({ isVisibleBackdrop: true });
    try {
      // set({ isLoading: true });
      const phone = useUserStore.getState().user?.phone;
      const response = await postBasketPromocode({
        promocode: code,
        phone,
      });

      if (response?.status === 'success') {
        // const responseAmount =
        //   await postAmountCheck({
        //     products:
        //       response?.data.items.map(
        //         (item) => item.product.id
        //       ) || [],
        //     city:
        //       useCityStore.getState().city ||
        //       'default',
        //   });

        // const responseAmountCity =
        //   await postBasketAvailable({
        //     city:
        //       useCityStore.getState().city ||
        //       'default',
        //   });

        if (response?.data.errortext) {
          set({
            // cart: response?.data.items,
            // totalItems: response?.data.items.length,
            promocodeStatus: 'error',
            promocodeError: response?.data.errortext,
            // amount: responseAmount,
            // amountCity: responseAmountCity,
          });
        } else {
          set({
            // cart: response?.data.items,
            // totalItems: response?.data.items.length,
            promocodeStatus: 'success',
            promocode: code,
            promocodeDiscount: Math.round(response?.data.calculation.totaldiscount || 0),
            // amount: responseAmount,
            // amountCity: responseAmountCity,
          });
        }
      } else {
        set({
          promocodeStatus: 'error',
        });
      }
    } catch (error) {
      console.error('Failed to set promocode', error);
    } finally {
      set({ isVisibleBackdrop: false });
      // set({ isLoading: false });
    }
  },

  getDeliveries: async () => {
    set({
      isDeliveriesLoading: true,
      isVisibleBackdrop: true,
    });
    try {
      const response = await getDeliveryCities();
      const city = response?.find((item) => item.city_name === useCityStore.getState().cityName);
      const cdek = city?.cdek_code
        ? await postDeliveryCdekPoints({
            city: city?.cdek_code,
          })
        : [];
      const cdekTariff = await postDeliveryCdekTariff({
        city: city?.cdek_code || '',
      });
      const boxberry = city?.boxberry_code
        ? await postDeliveryBoxberryPoints({
            city: city?.boxberry_code,
          })
        : [];

      const cityName = useCityStore.getState().cityName;
      const cityCode = useCityStore.getState().city;

      const stateCity = useCityStore.getState().cities.find((item) => item.name === cityName);

      const russianPost = stateCity?.coordinates
        ? await postDeliveryRussianPostPoints({
            latitude: Number(stateCity.coordinates.split(',')[0]),
            longitude: Number(stateCity.coordinates.split(',')[1]),
            radius: 30,
          })
        : [];

      const deliveries = await postDeliveryGetAll({ city: cityCode || '' });

      set({
        cdekPoints: cdek,
        cdekTariff,
        boxberryPoints: boxberry,
        russianPostPoints: russianPost,
        pvzCount: (cdek?.length || 0) + (boxberry?.length || 0) || 0,
        deliveries,
      });
    } catch (error) {
      console.error('Failed to get deliveries', error);
    } finally {
      set({
        isDeliveriesLoading: false,
        isVisibleBackdrop: false,
      });
    }
  },
  setActiveStore: (activeStore) => set({ activeStore }),
  setActivePvz: (activePvz) => set({ activePvz }),
  setCart: (cart) => set({ cart }),

  initializeCart: async () => {
    set({
      isLoading: true,
      isVisibleBackdrop: true,
    });
    try {
      const response = await getBasket();
      console.log(response, 'response getBasket');

      const availByCityResponse = await postAvailByCity({
        products: response?.items.map((item) => item.product.id) || [],
        city: useCityStore.getState().city || 'default',
        uf_mainstore_delivery: !useCityStore.getState().city,
      });

      const responseAmount = availByCityResponse?.store_amount || [];
      const responseAmountCity = availByCityResponse?.avail_by_city;

      set({
        cart: response?.items,
        totalItems: response?.items.length,
        amount: responseAmount,
        amountCity: responseAmountCity,
      });

      await useCityStore.getState().getCityShops(useCityStore.getState().city);
      await useFavoritesStore.getState().getFavoriteProducts();
    } catch (error) {
      console.error('Failed to initialize cart', error);
    } finally {
      set({
        isLoading: false,
        isVisibleBackdrop: false,
      });
    }
  },

  addToCart: async (id: number) => {
    set({ isVisibleBackdrop: true });
    try {
      const response = await postBasketAdd({
        product_id: id,
        quantity: 1,
      });

      console.log(response, 'response postBasketAdd');

      const availByCityResponse = await postAvailByCity({
        products: response?.items.map((item) => item.product.id) || [],
        city: useCityStore.getState().city || 'default',
        uf_mainstore_delivery: !useCityStore.getState().city,
      });
      const responseAmount = availByCityResponse?.store_amount || [];
      const responseAmountCity = availByCityResponse?.avail_by_city;
      set({
        cart: response?.items,
        totalItems: response?.items.length,
        amount: responseAmount,
        amountCity: responseAmountCity,
        promocodeDiscount: 0,
        promocodeError: '',
        promocode: '',
        promocodeStatus: null,
      });
    } catch (error) {
      console.error('Failed to add to cart', error);
    } finally {
      set({ isVisibleBackdrop: false });
    }
  },

  setQuantity: async (id: number, quantity: number) => {
    set({ isVisibleBackdrop: true });
    try {
      const response = await postBasketSetQuantity({
        id,
        quantity,
      });
      const availByCityResponse = await postAvailByCity({
        products: response?.items.map((item) => item.product.id) || [],
        city: useCityStore.getState().city || 'default',
        uf_mainstore_delivery: !useCityStore.getState().city,
      });
      const responseAmount = availByCityResponse?.store_amount || [];
      const responseAmountCity = availByCityResponse?.avail_by_city;

      set({
        cart: response?.items,
        totalItems: response?.items.length,
        amount: responseAmount,
        amountCity: responseAmountCity,
        promocodeDiscount: 0,
        promocodeError: '',
        promocode: '',
        promocodeStatus: null,
      });
    } catch (error) {
      console.error('Failed to set quantity', error);
    } finally {
      set({ isVisibleBackdrop: false });
    }
  },

  delete: async (id: number) => {
    set({ isVisibleBackdrop: true });
    try {
      const response = await postBasketDelete({
        id,
      });
      const availByCityResponse = await postAvailByCity({
        products: response?.items.map((item) => item.product.id) || [],
        city: useCityStore.getState().city || 'default',
        uf_mainstore_delivery: !useCityStore.getState().city,
      });
      const responseAmount = availByCityResponse?.store_amount || [];
      const responseAmountCity = availByCityResponse?.avail_by_city;

      set({
        cart: response?.items,
        totalItems: response?.items.length,
        amount: responseAmount,
        amountCity: responseAmountCity,
        promocodeDiscount: 0,
        promocodeError: '',
        promocode: '',
        promocodeStatus: null,
      });
    } catch (error) {
      console.error('Failed to delete item', error);
    } finally {
      set({ isVisibleBackdrop: false });
    }
  },

  deleteArray: async (positions: number[]) => {
    set({ isVisibleBackdrop: true });
    try {
      const response = await postBasketDeleteArray({
        positions,
      });
      const availByCityResponse = await postAvailByCity({
        products: response?.items.map((item) => item.product.id) || [],
        city: useCityStore.getState().city || 'default',
        uf_mainstore_delivery: !useCityStore.getState().city,
      });
      const responseAmount = availByCityResponse?.store_amount || [];
      const responseAmountCity = availByCityResponse?.avail_by_city;

      set({
        cart: response?.items,
        totalItems: response?.items.length,
        amount: responseAmount,
        amountCity: responseAmountCity,
        promocodeDiscount: 0,
        promocodeError: '',
        promocode: '',
        promocodeStatus: null,
      });
    } catch (error) {
      console.error('Failed to delete item', error);
    } finally {
      set({ isVisibleBackdrop: false });
    }
  },

  getAvailableProducts: () => {
    const { amountCity } = get();
    const result = amountCity?.items.filter((item) => item.available && item.available.quantity > 0);
    return result || [];
  },

  getProductsByIds: (ids: number[]) => {
    const { cart } = get();
    return cart?.filter((item) => {
      return ids.includes(item.product_id);
    });
  },

  getUnavailableProducts: () => {
    const { amountCity } = get();

    const result = amountCity?.items.filter((item) => item.not_available && item.not_available.quantity > 0);
    return result || [];
  },
}));
