export const API = {
  // PAGES
  getMainPage: '/mainpage/get',
  getPopularSections: '/mainpage/popular-sections',
  getBrands: '/mainpage/brands',
  getSets: '/mainpage/all-sets',
  getProductAmount: '/catalog/get-store-amount/',

  getSetBySlug: (slug: string) => `/sets/${slug}`,

  // SHOPS
  getShops: '/shops/all',

  //REVIEWS
  getReviews: '/reviews/all',

  //USER
  getUser: '/user/get',

  //BASKET
  getBasket: '/basket/get',
  postBasketAdd: '/basket/add',
  postBasketSetQuantity: (id: number) => `/basket/set-quantity/${id}`,
  postBasketDelete: (id: number) => `/basket/delete/${id}`,
  postBasketDeleteArray: '/basket/delete',
  postBasketPromocode: '/order/promocode-check',
  postAvailByCity: '/basket/avail-by-city',

  // BLOG
  getBlog: (page: number) => `/blog/all?page=${page}`,
  getBlogByProduct: (slug: string, page: number) => `/blog/by-product/${slug}?page=${page}`,

  // CATALOG
  getCatalogProducts: (page: number) => `/catalog/products?page=${page}`,
  getCatalogSectionProducts: (code: string, page: number) => `/catalog/sections/${code}/products?page=${page}`,
  getProduct: (slug: string) => `/catalog/product/${slug}`,
  getCatalogSections: '/catalog/sections',
  getCatalogSectionBySlug: (slug: string) => `/catalog/sections/${slug}`,
  getCatalogProductsAll: '/catalog/products/all',
  getCatalogCards: (slug: string) => `/catalog/cards/${slug}`,
  getCatalogBlocks: '/catalog/blocks',

  // HISTORY
  postHistory: '/history/add',
  getHistory: '/history/get',

  // DELIVERY
  getDeliveryCities: '/delivery/cities',
  postDeliveryCdekTariff: '/delivery/cdek/tariff',
  postDeliveryGetAll: '/delivery/get-all',
  postDeliveryCdekPoints: '/delivery/cdek/points',
  postDeliveryBoxberryPoints: '/delivery/boxberry/points',
  postDeliveryRussianPostPoints: '/delivery/russianpost/points',

  // ORDER
  postOrderCreate: '/order/create',
  postOrderToOrder: '/order/to-order',

  // favorites
  getFavorites: '/favorite/get',

  // CATALOG FILTER
  postCatalogFilterState: '/catalog/filter/state',
  postCatalogFilterApply: '/catalog/filter/apply',
  postCatalogFilterCount: '/catalog/filter/count',
  // MENU
  getMenu: '/menu',

  // FAVORITE
  postFavoriteAdd: '/favorite/add',
  postFavoriteDelete: '/favorite/delete',

  // SEARCH
  postSearchQuery: '/search/query',
  postSearchHistoryPush: '/search/history/push',
  getSearchHistory: '/search/history/get',
  getSearchOftenSearched: '/search/often-searched',
  getSearchRecommendProducts: '/search/reccomend-products',

  // FORM
  postFormSubmit: '/form/submit',

  // REDIRECTS
  getRedirects: '/redirects',
};
