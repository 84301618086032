import axios, { InternalAxiosRequestConfig } from 'axios';
import { CACHE_TTL_DEFAULT } from '@/shared/constants';
import { baseAxiosHeaders } from './prepareHeaders';
import { setupCache, buildMemoryStorage } from 'axios-cache-interceptor';

const API_URL = process.env.NEXT_PUBLIC_FRONT_API_URL;
const PROXY_API_URL = process.env.NEXT_PUBLIC_FRONT_PROXY_API_URL;

export const DATE_CREATE = Date.now();
const mainInstance = axios.create({
  headers: baseAxiosHeaders,
  withCredentials: true,
  baseURL: typeof window === 'undefined' ? API_URL : PROXY_API_URL,
});

const additionalInstance = axios.create({
  headers: baseAxiosHeaders,
  withCredentials: true,
  baseURL: typeof window === 'undefined' ? API_URL : PROXY_API_URL,
});

export const memoryStorage = buildMemoryStorage(
  false,
  // Запуск интервала по очистке старых записей раз в 10 минут
  Number(process.env.NEXT_PUBLIC_MAIN_INSTANCE_AXIOS_CLEANUP_INTERVAL) || 10 * 60 * 1000,
  // Максимальное количество записей, которые можно хранить в хранилище. Используется умный порядок FIFO
  Number(process.env.NEXT_PUBLIC_MAIN_INSTANCE_AXIOS_MAX_ENTRIES) || 300
);

// Основной инстанс с ограниченным кэшем
const interpretHeaderMainInstance = process.env.NEXT_PUBLIC_MAIN_INSTANCE_AXIOS_INTERPRET_HEADER === 'true';

export const axiosInstance = setupCache(mainInstance, {
  ttl: CACHE_TTL_DEFAULT,
  interpretHeader: interpretHeaderMainInstance,
  storage: memoryStorage,
});

// Дополнительный инстанс кэшем без ограничения
const interpretHeaderAdditionalInstance = process.env.NEXT_PUBLIC_ADDITIONAL_INSTANCE_AXIOS_INTERPRET_HEADER === 'true';
export const additionalAxiosInstance = setupCache(additionalInstance, {
  ttl: CACHE_TTL_DEFAULT,
  interpretHeader: interpretHeaderAdditionalInstance,
});

const authRequestInterceptor = (config: InternalAxiosRequestConfig) => {
  const token = typeof window !== 'undefined' ? localStorage.getItem('token') : null;

  if (token) {
    config.headers['Authorization-Token'] = token;
  }

  return config;
};

axiosInstance.interceptors.request.use(authRequestInterceptor);

additionalAxiosInstance.interceptors.request.use(authRequestInterceptor);
